import $ from 'jquery';

class Tabs {
  constructor() {
    this.initTabs();
  }

  initTabs() {
    let tabs = $('[data-js-tabs]');

    if (tabs.length) {
      let that = this;

      let controls = tabs.find('[data-js-tabs-control]');
      let views = $(tabs).find('[data-js-tabs-tab]');
      const tabActiveClass = $(tabs).data('js-tabs-tab-active');
      const controlActiveClass = $(tabs).data('js-tabs-control-active');

      $(tabs).on('click', '[data-js-tabs-control]', function (e) {
        e.preventDefault();

        let viewNumber = controls.index(this);
        if (viewNumber < views.length) {
          that.swapIcon($(this).find('img'));
          that.swapIcon($('.' + controlActiveClass).find('img'));

          controls.removeClass(controlActiveClass);
          $(this).addClass(controlActiveClass);

          views.removeClass(tabActiveClass);
          $(views[viewNumber]).addClass(tabActiveClass);

          window.Waypoint.refreshAll();
        }
      });
    }
  }

  swapIcon(icon) {
    let toggl = icon.attr('src');
    icon.attr('src', icon.data('icon-toggle'));
    icon.data('icon-toggle', toggl);
  }
}

export default Tabs;
