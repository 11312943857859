//@flow
import $ from 'jquery';

const SCROLLBY = 100;

class Testimonial {
  constructor() {
    this.$moreBtn = $('[data-js-testimonial-more]');
    this.$closeBtn = $('[data-js-testimonial-close ]');
    this.$full = $('[data-js-testimonial-full]');
    this.$body = $('[data-js-body]');
    this.$popup = $('[data-js-testimonial-popup]');
    this.$container = $('[data-js-testimonial-container]');
    this.$testimonialScroll = $('[data-js-testimonial-scroll]');
    this.index;
    this.bodyScroll = 0;
    this.EventListener();
  }

  EventListener() {
    this.$moreBtn.on('click', event => {
      event.preventDefault();
      this.index = $(event.target)
        .parents('[data-js-testimonial-excerpt]')
        .data('testimonial-index');

      this.Open();
    });

    this.$closeBtn.on('click', () => {
      this.Close();
    });

    this.scrollButtonListener();
  }

  Open() {
    this.bodyScroll = window.pageYOffset;
    this.$popup.addClass('is-open');
    $(this.$full[this.index]).addClass('is-open');
    this.$body.addClass('is-locked');
    this.setScrollable();
    $(window).on('resize.testimonial', () => this.setScrollable());
  }

  setScrollable() {
    if (
      this.$full.eq(this.index).outerHeight() <
      this.$container[this.index].scrollHeight
    ) {
      this.$testimonialScroll.eq(this.index).addClass('scrollable');
    } else {
      this.$testimonialScroll.eq(this.index).removeClass('scrollable');
    }
  }

  Close() {
    $(window).off('resize.testimonial');
    this.$popup.removeClass('is-open');
    $(this.$full[this.index]).removeClass('is-open');
    this.$body.removeClass('is-locked');
    window.scrollTo(0, this.bodyScroll);
  }

  scrollButtonListener() {
    this.$testimonialScroll.on('click', () => {
      const $currentContainer = this.$container.eq(this.index);
      $currentContainer.animate(
        {
          scrollTop: $currentContainer[0].scrollTop + SCROLLBY
        },
        'slow'
      );
    });
  }
}

export default Testimonial;
