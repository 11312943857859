//@flow
import $ from 'jquery';

class Collapse {
  constructor() {
    this.$topBar = $('[data-js-treat-bar]');
    this.$content = $('[data-js-treat-content]');
    this.$openIcon = $('[data-js-treat-icon-open]');
    this.$closeIcon = $('[data-js-treat-icon-close]');

    this.Listener();
  }

  Listener() {
    this.$topBar.on('click', () => {
      if (this.$content.hasClass('is-collapsed')) {
        this.Close();
      } else {
        this.Open();
      }
    });
  }

  Open() {
    this.$openIcon.removeClass('is-shown');
    this.$closeIcon.addClass('is-shown');
    this.$content.addClass('is-collapsed');
  }

  Close() {
    this.$openIcon.addClass('is-shown');
    this.$closeIcon.removeClass('is-shown');
    this.$content.removeClass('is-collapsed');
  }
}

export default Collapse;
