//@flow
import $ from 'jquery';

const MOBILEBREAKPOINT = 768;
class Expanding {
  constructor() {
    this.$trigger = $('[data-js-expand-head]');
    this.$expandBodies = $('[data-js-expand-body]');
    this.effects;
    this.EventListener();
    this.ResizeListener();
    $(window).trigger('resize');
  }

  EventListener() {
    this.$trigger.on('click', event => {
      if ($(event.target).hasClass('is-active') && this.effects) {
        this.Close(event.target);
      } else if (this.effects) {
        this.Open(event.target);
      }
    });
  }

  Open(element) {
    let $body = $(element).siblings('[data-js-expand-body]');
    let height = $body.find('[data-js-expand-body-content]').outerHeight();
    $(element).addClass('is-active');
    $body.addClass('is-expanded').css('max-height', height);
  }

  Close(element) {
    let $body = $(element).siblings('[data-js-expand-body]');
    $(element).removeClass('is-active');
    $body.removeClass('is-expanded').css('max-height', 0);
  }

  setHeight(elementsArray, height) {
    elementsArray.each(function() {
      $(this).css('max-height', height);
    });
  }

  ResizeListener() {
    $(window).on('resize', () => {
      this.windowWidth = $(window).width();
      if (this.windowWidth < MOBILEBREAKPOINT) {
        if (!this.effects) {
          this.setHeight(this.$expandBodies, 0);
        }
        this.effects = true;
      } else {
        if (this.effects) {
          this.setHeight(this.$expandBodies, '100%');
        }
        this.effects = false;
      }
    });
  }
}

export default Expanding;
