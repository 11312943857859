// @flow

import $ from 'jquery';
import 'slick-carousel';

const sliderOptions = {
  infinite: true,
  dots: false,
  arrows: true,
  accessibility: false,
  slidesToShow: 1,
  prevArrow:
    '<div class="c-slider__arrow c-slider__arrow--prev"><div class="c-slider__icon-arrow  c-slider__icon-arrow--prev"></div></div>',
  nextArrow:
    '<div class="c-slider__arrow c-slider__arrow--next"><div class="c-slider__icon-arrow  c-slider__icon-arrow--next"></div></div>',
  slidesToScroll: 1
};

class Slider {
  constructor() {
    this.initSlider();
    this.initVideoSlider();
    this.initPublicationSlider();
  }

  initSlider() {
    $('[data-js-slider]').slick(sliderOptions);
  }

  initVideoSlider() {
    this.initBigSlider();
    this.initThumbnails();
  }

  initPublicationSlider() {
    $('[data-js-publications-slider]').slick({
      slidesToShow: 3,
      accessibility: false,
      centerPadding: 0,
      slidesToScroll: 3,
      dots: false,
      arrows: true,
      prevArrow:
        '<div class="c-slider-publications__arrow c-slider-publications__arrow--prev"><div class="c-slider-publications__icon-arrow  c-slider-publications__icon-arrow--prev"></div></div>',
      nextArrow:
        '<div class="c-slider-publications__arrow c-slider-publications__arrow--next"><div class="c-slider-publications__icon-arrow  c-slider-publications__icon-arrow--next"></div></div>',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });
  }

  initBigSlider() {
    $('[data-js-video-slider]').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      accessibility: false,
      fade: true,
      asNavFor: '[data-js-video-slider-thumbnails]'
    });
  }

  initThumbnails() {
    $('[data-js-video-slider-thumbnails]').slick({
      slidesToShow: 3,
      accessibility: false,
      centerPadding: 0,
      slidesToScroll: 1,
      asNavFor: '[data-js-video-slider]',
      dots: false,
      arrows: true,
      centerMode: true,
      prevArrow:
        '<div class="c-slider-video__arrow c-slider-video__arrow--prev"><div class="c-slider-video__icon-arrow  c-slider-video__icon-arrow--prev"></div></div>',
      nextArrow:
        '<div class="c-slider-video__arrow c-slider-video__arrow--next"><div class="c-slider-video__icon-arrow  c-slider-video__icon-arrow--next"></div></div>',
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false
          }
        }
      ]
    });
  }
}

export default Slider;
