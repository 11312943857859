//@flow

import $ from 'jquery';

class FAQ {
  constructor() {
    this.$question = $('[data-js-question]');

    this.ClickListener();
  }

  ClickListener() {
    this.$question.on('click', event => {
      if (!$(event.target).hasClass('is-active')) {
        this.Open($(event.target));
      } else {
        this.Close($(event.target));
      }
    });
  }

  Open(question) {
    let $item = question.siblings('[data-js-answer]');
    question.addClass('is-active');
    $item.css(
      'max-height',
      $item.find('[ data-js-answer-content]').outerHeight()
    );
    question.find('[data-js-question-icon]').addClass('is-active');
  }

  Close(question) {
    let $item = question.siblings('[data-js-answer]');
    $item.css('max-height', 0);
    question.removeClass('is-active');
    question.find('[data-js-question-icon]').removeClass('is-active');
  }
}

export default FAQ;
