export default new class {
  constructor() {
    this.selectors = {
      navigation: '.c-blog-navigation',
      content: '.c-blog-list__main'
    };

    this.elements = {
      navigation: document.querySelector(this.selectors.navigation),
      content: document.querySelector(this.selectors.content)
    };

    this.activeClasses = {
      navigationFixed: 'is-fixed',
      navigationAbsolute: 'is-absolute'
    };

    this.settings = {
      PAGE_POSITION_TOP: 0,
      POSITION_TRIGGER_DISTANCE_FROM_TOP: 60,
      MINIMUM_CONTENT_HEIGHT: 600,
    };

    this.state = {
      previousVerticalPagePosition: null
    };
  }

  init() {
    this.calculatePosition();
  }

  calculateVerticalPagePosition() {

    if (pageYOffset) {
      return pageYOffset;
    } else if (document.documentElement.clientHeight) {
      return document.documentElement.scrollTop;
    } else if (document.body) {
      return document.body.scrollTop;
    }

    return this.settings.PAGE_POSITION_TOP;
  }

  calculatePosition() {
    const { elements, activeClasses, settings, state } = this;

    if (
      !elements.navigation ||
      (elements.navigation && elements.content.clientHeight < settings.MINIMUM_CONTENT_HEIGHT)
    ) {
      return;
    }

    const verticalPagePosition = this.calculateVerticalPagePosition();

    const contentPosition = elements.content.getBoundingClientRect();
    const contentPositionTop = contentPosition.top + elements.content.clientHeight;

    const navigationPosition = elements.navigation.getBoundingClientRect();
    const navigationPositionTop = navigationPosition.top + elements.navigation.clientHeight;

    if (verticalPagePosition >= settings.POSITION_TRIGGER_DISTANCE_FROM_TOP) {
      elements.navigation.classList.add(activeClasses.navigationFixed);
    }

    if (
      verticalPagePosition > settings.PAGE_POSITION_TOP &&
      !state.previousVerticalPagePosition &&
      verticalPagePosition > elements.navigation.clientHeight + settings.POSITION_TRIGGER_DISTANCE_FROM_TOP
    ) {
      elements.navigation.classList.add(activeClasses.navigationAbsolute);
    }

    if (contentPositionTop <= navigationPositionTop && verticalPagePosition > state.previousVerticalPagePosition) {
      elements.navigation.classList.add(activeClasses.navigationAbsolute);
    }

    if (verticalPagePosition < elements.content.clientHeight - elements.navigation.clientHeight) {
      elements.navigation.classList.remove(activeClasses.navigationAbsolute);
    }

    if (verticalPagePosition < settings.POSITION_TRIGGER_DISTANCE_FROM_TOP) {
      elements.navigation.classList.remove(activeClasses.navigationFixed);
    }

    state.previousVerticalPagePosition = verticalPagePosition;
  }
}
