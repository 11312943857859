export default new class {
  constructor() {
    this.selectors = {
      heading: '.c-post-heading',
      title: '.c-post-content__title',
      headingTitle: '.c-post-heading__title'
    };

    this.elements = {
      heading: document.querySelector(this.selectors.heading),
      title: document.querySelector(this.selectors.title),
      headingTitle: document.querySelector(this.selectors.headingTitle)
    };

    this.activeClasses = {
      titleVisible: 'is-visible'
    };
  }

  init() {
    this.calculatePosition();
  }

  calculatePosition() {
    const { elements, activeClasses } = this;

    if ( !elements.heading ) {
      return;
    }

    const titlePosition = this.findTitleBottom();
    const headingPosition = this.findHeadingBottom();

    if(headingPosition > titlePosition) {
      elements.headingTitle.classList.add(activeClasses.titleVisible);
    } else {
      elements.headingTitle.classList.remove(activeClasses.titleVisible);
    }
  }

  findTitleBottom() {
    const { elements } = this;

    const titlePosition = elements.title.getBoundingClientRect();

    return titlePosition.bottom;
  }

  findHeadingBottom() {
    const { elements } = this;

    const headingPosition = elements.heading.getBoundingClientRect();

    return headingPosition.bottom;
  }

  calculateVerticalPagePosition() {

    if (pageYOffset) {
      return pageYOffset;
    } else if (document.documentElement.clientHeight) {
      return document.documentElement.scrollTop;
    } else if (document.body) {
      return document.body.scrollTop;
    }

    return this.settings.PAGE_POSITION_TOP;
  }
}
