//@flow
import $ from 'jquery';

class Logo {
  constructor() {
    this.$logo = $('[data-js-hero-logo]');
    this.logoHeight = this.$logo.outerHeight();
    this.factor = 1.3;
    this.ScrollListener();
  }

  ScrollListener() {
    $(window).on('scroll', () => {
      this.$scrollTop = $(window).scrollTop();
      this.LogoEffect();
    });
  }

  LogoEffect() {
    this.$logo.css({
      opacity: (this.logoHeight - this.$scrollTop * this.factor) / this.logoHeight,
      top: this.$scrollTop
    });
  }
}

export default Logo;
