import $ from 'jquery';
import 'waypoints/lib/noframework.waypoints.min';

const MAX_HEIGHT = 300;

export default class SubNvigation {
  constructor() {
    this.menuHeightShrinked = 100;
    this.menuHeight = 140;
    this.subNavigation = document.querySelector('[data-js-subnavigation]');
    this.spacer = document.querySelector('[data-js-subnavigation-spacer]');
    this.navigationDesktop = document.querySelector('[data-js-nav]');
    this.navigationMobile = document.querySelector('[data-js-nav-mobile]');
    this.mobileContainer = document.querySelector(
      '[data-js-subnavigation-mobile-container]'
    );
    this.mobileToggle = document.querySelector(
      '[data-js-subnavigation-mobile-toggle]'
    );
    this.mobileContent = document.querySelector(
      '[data-js-subnavigation-mobile-content]'
    );

    if (
      !(
        this.subNavigation &&
        this.spacer &&
        this.navigationDesktop &&
        this.navigationMobile &&
        this.mobileContainer &&
        this.mobileToggle &&
        this.mobileContent
      )
    ) {
      return;
    }

    this.desktopBreakpoint = window.matchMedia('(min-width: 1201px)');
    this.closedNavHeight = 0;
    this.isAlwaysStuck =
      'jsSubnavigationAlwaysStuck' in this.subNavigation.dataset;

    this.setup();

    $(this.navigationDesktop).on('shrinkMenu', () => {
      this.setSubNavPosition();
    });
  }

  setup() {
    this.mobileToggle.addEventListener('click', () => this.toggleMobileMenu());

    this.mobileContent.addEventListener(
      'transitionend',
      () => {
        this.mobileContainer.classList.remove('animating');
        this.mobileContent.style.height = '';
      },
      false
    );

    if (this.isAlwaysStuck) {
      this.toggleStickyNav();
    } else {
      new window.Waypoint({
        element: this.spacer,
        handler: () => this.toggleStickyNav(),
        offset: () => this.getMainNavHeight()
      });
    }

    this.desktopBreakpoint.addListener(() => this.setSubNavPosition());
    this.setSubNavPosition();

    // Clicks
    $(this.subNavigation)
      .find('[data-js-subnavigation-scroll]')
      .click(e => this.go(e));
  }

  toggleMobileMenu() {
    const isOpen = this.mobileContainer.classList.contains('is-open');

    const element = this.mobileContainer;
    const content = this.mobileContent;

    if (!isOpen) {
      element.classList.add('animating');
      content.style.height = Math.min(content.scrollHeight, MAX_HEIGHT) + 'px';
    } else {
      content.style.height = content.offsetHeight + 'px';
      element.classList.add('animating');
      window.getComputedStyle(content).transform;
      content.style.height = '';
    }
    element.classList.toggle('is-open');
  }

  toggleStickyNav() {
    this.subNavigation.classList.toggle('is-sticky');
    this.setSubNavPosition();
  }

  getMainNavHeight() {
    let element = document.querySelector('.c-nav__main');

    return this.desktopBreakpoint.matches
      ? element.classList.contains('is-shrinked')
        ? this.menuHeightShrinked
        : this.menuHeight
      : this.navigationMobile.offsetHeight;
  }

  setSubNavPosition() {
    this.closedNavHeight = this.subNavigation.offsetHeight;
    if (this.subNavigation.classList.contains('is-sticky')) {
      this.subNavigation.style.top = this.getMainNavHeight() + 'px';
      this.spacer.style.height = this.closedNavHeight + 'px';
    } else {
      this.subNavigation.style.top = this.spacer.style.height = '';
    }
  }

  go(e) {
    e.preventDefault();

    $('html,body').animate(
      {
        scrollTop:
          $($(e.target).attr('href')).offset().top -
          this.getMainNavHeight() -
          (!this.subNavigation.classList.contains('is-sticky')
            ? this.subNavigation.offsetHeight - this.closedNavHeight
            : 0) -
          this.closedNavHeight
      },
      'slow'
    );

    if (!this.desktopBreakpoint.matches) {
      this.toggleMobileMenu();
    }
  }
}
