/*
  Project: UWC V2
  Author: Xfive
 */

import Navigation from './Navigation';
import Slider from './slider';
import Tabs from './tabs';
import ProgressAnimation from './progressAnimation';
import TreatCollapse from './sectionCollapse';
import Expanding from './expanding';
import MobilePagination from './MobilePagination';
import SlideDown from './slideDown';
import Video from './video';
import Logo from './logo';
import ArticleTitle from './ArticleTitle';
import BlogSideNavigation from './BlogSideNavigation';
import Newsletter from './newsletter';
import Search from './search';
import faq from './faq';
import SubNavigation from './SubNavigation';
import Testimonials from './testimonial';

window.onload = () => {
  Navigation.init();
  new Slider();
  new TreatCollapse();
  new Tabs();
  new ProgressAnimation();
  new Expanding();
  new MobilePagination();
  new SlideDown();
  new Video();
  new Logo();
  ArticleTitle.init();
  BlogSideNavigation.init();
  new Newsletter();
  new Search();
  new faq();
  new SubNavigation();
  new Testimonials();
};

window.onscroll = () => {
  BlogSideNavigation.calculatePosition();
  ArticleTitle.calculatePosition();
};
