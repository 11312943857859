//@flow

import $ from 'jquery';

class Search {
  constructor() {
    this.$searchBtn = $('[data-js-top-search-open]');
    this.$search = $('[data-js-top-search]');
    this.$body = $('[data-js-body]');
    this.EventListener();
  }

  EventListener() {
    this.$searchBtn.on('click', event => {
      event.preventDefault();
      if ($(event.target).hasClass('is-active')) {
        this.Close();
      } else {
        this.Open();
      }
    });

    $('[data-js-top-search-bg]').on('click', () => {
      this.Close();
    });
  }

  Open() {
    this.$search.addClass('is-active');
    this.$searchBtn.addClass('is-active');
    this.$body.addClass('is-locked');
  }

  Close() {
    this.$search.removeClass('is-active');
    this.$searchBtn.removeClass('is-active');
    this.$body.removeClass('is-locked');
  }
}

export default Search;
