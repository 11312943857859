//@flow
import $ from 'jquery';

class Video {
  constructor() {
    this.Play();
  }

  Play() {
    $('[data-js-video-patch]').on('click', event => {
      if (!$(event.target).hasClass('is-active')) {
        this.Open(event.target);
      } else {
        this.Close(event.target);
      }
    });
  }

  Open(element) {
    $(element).addClass('is-active');
    this.PlayVideo(element);
  }

  Close(element) {
    $(element).removeClass('is-active');
  }

  PlayVideo(item) {
    let $video = $(item).find('iframe')[0].src;

    if ($video.indexOf('vimeo') >= 0) {
      $(item).find('iframe')[0].src += '?api=1';
    }

    $(item).find('iframe')[0].src += '&autoplay=1';
  }
}

export default Video;
