import $ from 'jquery';

const slideDownOptions = {
  triggerSelector: '.c-better-health__box-switch',
  wrapperSelector: '.c-better-health__box-wrapper',
  toggleClass: 'c-better-health__box--closed'
};

class SlideDown {
  constructor() {
    this.initSlideDown();
  }

  initSlideDown() {
    $(slideDownOptions.triggerSelector).on('click', function () {
      $(this).prev().toggleClass(slideDownOptions.toggleClass);
    });
  }
}

export default SlideDown;
