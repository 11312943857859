import $ from 'jquery';

const newsletterOptions = {
  buttonSelector: '.c-newsletter__submit',
  loadingClass: 'c-newsletter__loading',
  messageBoxClass: 'c-newsletter__message'
};

class Newsletter {
  constructor() {
    this.initNewsletter();
  }

  initNewsletter() {
    const that = this;
    this.cont = $('.' + newsletterOptions.messageBoxClass);
    $(newsletterOptions.buttonSelector).on('click', function (e) {
      e.preventDefault();
      let regex = '^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$';
      let email = $(this).prev('.c-newsletter__email').val();
      if (email.match(regex)) {
        that.cont.html('');
        $(this).addClass(newsletterOptions.loadingClass);
        $.ajax({
          method: "POST",
          url: "/wp-json/uwc/v1/newsletter/subscribe",
          data: {email: email}
        }).done(that.addMessage.bind(that));
      } else {
        that.addMessage({type: 'error', message: 'Incorrect email format.'});
      }
    });
  }

  addMessage(response) {
    $(newsletterOptions.buttonSelector).removeClass(newsletterOptions.loadingClass);
    let msgClass = newsletterOptions.messageBoxClass;
    this.cont.attr('class', msgClass + ' ' + msgClass + '--' + response.type);
    this.cont.html(response.message);
  }
}


export default Newsletter;
