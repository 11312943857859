import $ from 'jquery';
import 'waypoints/lib/noframework.waypoints.min';

class ProgressAnimation {
  constructor() {
    this.selectors = {
      activityImage: '.c-activity-list__image-wrapper',
      activityDescription: '.c-activity-list__description'
    }

    this.activeClasses = {
      activityItemVisible: 'is-visible'
    }

    this.initAnimation();
  }

  initAnimation() {
    let rows = $('.c-activity-list__row');

    const FIRST = 0;
    const WAYPOINT_OFFSET_MODIFIER = 1.2;


    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      let { selectors, activeClasses } = this;
      const progressBar = $(row).parent().find('.c-activity-list__progress')[FIRST];

      new window.Waypoint({
        element: row,
        handler(direction) {
          const rowPosition = row.getBoundingClientRect();
          const progressBarPosition = progressBar.getBoundingClientRect();
          let height = 0;
          const rowHeight = $(row).outerHeight();

          if (direction === 'down') {
            height = rowPosition.top - progressBarPosition.top + rowHeight;
            $(row).addClass('c-activity-list__row--active');
            $(`${selectors.activityImage}, ${selectors.activityDescription}`, row).addClass(activeClasses.activityItemVisible);
          } else {
            height = rowPosition.top - progressBarPosition.top;
            $(row).removeClass('c-activity-list__row--active');
            $(`${selectors.activityImage}, ${selectors.activityDescription}`, row).removeClass(activeClasses.activityItemVisible);
          }
          $(progressBar).css('height', (height) + 'px');
        },
        offset() {
          const rowHeight = $(row).outerHeight();
          return $(window).height() * WAYPOINT_OFFSET_MODIFIER - rowHeight;
        },
      });
    }
  }
}

export default ProgressAnimation;
