import $ from 'jquery';

export default new class {
  constructor() {
    this.nav = $('[data-js-nav-main]');
    this.logo = $('[data-js-nav-logo]');
    this.menuShrinkBreakpoint = 100;
    this.selectors = {
      trigger: '.c-nav__close, .c-mobile-nav__open',
      container: '.c-nav'
    };
    this.elements = {
      trigger: document.querySelectorAll(this.selectors.trigger),
      container: document.querySelector(this.selectors.container),
      body: document.querySelector('html')
    };
    this.activeClasses = {
      container: 'c-nav--active'
    };

    this.scrollListener();
    this.nav.on('transitionend', () => {
      window.Waypoint.refreshAll();
    });
  }

  init() {
    this.setupEvents();
  }

  setupEvents() {
    let trigger = this.elements.trigger;
    let that = this;
    if (trigger) {
      for (let i = 0; i < trigger.length; i++) {
        trigger[i].addEventListener('click', that.toggleNavigation.bind(that));
      }
    }
  }

  scrollListener() {
    $(window).on('scroll', () => {
      if (
        window.pageYOffset > this.menuShrinkBreakpoint &&
        !this.logo.hasClass('is-shrinked')
      ) {
        this.shrink();
      } else if (
        this.logo.hasClass('is-shrinked') &&
        window.pageYOffset < this.menuShrinkBreakpoint
      ) {
        this.grow();
      }
    });
  }

  shrink() {
    this.nav.addClass('is-shrinked');
    this.logo.addClass('is-shrinked');
    this.nav.trigger('shrinkMenu');
    $(document.body).attr('data-navigation-size', 'small');
  }

  grow() {
    this.nav.removeClass('is-shrinked');
    this.logo.removeClass('is-shrinked');
    this.nav.trigger('shrinkMenu');
    $(document.body).removeAttr('data-navigation-size');
  }

  toggleNavigation() {
    let elements = this.elements;
    let activeClasses = this.activeClasses;
    if (elements.container) {
      elements.container.classList.toggle(activeClasses.container);
      elements.body.classList.toggle('disable-scroll');
    }
  }
}();
