const MAX_HEIGHT = 200;
const HALF = 0.5;

export default class MobilePagination {
  constructor() {
    this.toggleBtn = document.querySelector(
      '[data-pagination-dropdown-toggle]'
    );
    this.content = document.querySelector('[data-pagination-dropdown-content]');

    if (!(this.toggleBtn && this.content)) {
      return;
    }

    this.setup();
  }

  setup() {
    this.toggleBtn.addEventListener('click', () => this.toggle());

    this.content.addEventListener(
      'transitionend',
      () => {
        this.content.classList.remove('animating');
        this.content.style.height = '';
      },
      false
    );
  }

  toggle() {
    if (!this.content.classList.contains('is-open')) {
      const top = this.toggleBtn.getBoundingClientRect().top;
      this.content.classList.remove('above');
      this.content.classList.remove('below');
      if (top > window.innerHeight * HALF) {
        this.content.classList.add('above');
      } else {
        this.content.classList.add('below');
      }

      this.content.classList.add('animating');
      this.content.style.height =
        Math.min(this.content.scrollHeight, MAX_HEIGHT) + 'px';
    } else {
      this.content.style.height = this.content.offsetHeight + 'px';
      this.content.classList.add('animating');
      window.getComputedStyle(this.content).transform;
      this.content.style.height = '';
    }
    this.content.classList.toggle('is-open');
  }
}
